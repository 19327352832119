import React from 'react';
import htmlOriginal from "../assets/img/html5-original.svg";
import cssOriginal from "../assets/img/css3-original.svg";
import sassOriginal from "../assets/img/sass-original.svg";
import bootstrapOriginal from "../assets/img/bootstrap-original.svg";
import javascriptOriginal from "../assets/img/javascript-original.svg";
import typescriptOriginal from "../assets/img/typescript-original.svg";
import reactOriginal from "../assets/img/react-original.svg";
import phpOriginal from "../assets/img/php-original.svg";
import laravelOriginal from "../assets/img/laravel-plain.svg";
// import csharpOriginal from "../assets/img/csharp-plain.svg";
import photoshop from "../assets/img/photoshop-plain.svg";
import xd from "../assets/img/xd-plain.svg";
import mysqlOriginal from "../assets/img/mysql-original-wordmark.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png";
<script async defer src="https://buttons.github.io/buttons.js"></script>

export const Testnet = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="testnet" id="testnet">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2> Mes connaissances ✨</h2>
                        <p> </p> 
                        <br></br>
                        <br></br>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                        <div className="item">
                            <a href="https://developer.mozilla.org/fr/docs/Web/HTML" target="_blank">  <img src={htmlOriginal} alt="Image" /></a>
                            <a href="https://developer.mozilla.org/fr/docs/Web/HTML" target="_blank">  <h5>HTML5</h5></a>
                            </div>
                            <div className="item">
                            <a href="https://developer.mozilla.org/fr/docs/Web/CSS" target="_blank"> <img src={cssOriginal} alt="Image" /></a>
                            <a href="https://developer.mozilla.org/fr/docs/Web/CSS" target="_blank">  <h5>CSS3</h5></a>
                            </div>
                            <div className="item">
                            <a href="https://sass-lang.com/" target="_blank"> <img src={sassOriginal} alt="Image" /></a>
                            <a href="https://sass-lang.com/" target="_blank"> <h5>Préprocesseur CSS (Sass)</h5></a>
                            </div>
                            <div className="item">
                            <a href="https://getbootstrap.com/" target="_blank"> <img src={bootstrapOriginal} alt="Image" /></a>
                            <a href="https://getbootstrap.com/" target="_blank"> <h5>Framework CSS (Bootstrap)</h5></a>
                            </div>
                            <div className="item">
                            <a href="https://developer.mozilla.org/fr/docs/Web/JavaScript" target="_blank"><img src={javascriptOriginal} alt="Image" /></a>
                            <a href="https://developer.mozilla.org/fr/docs/Web/JavaScript" target="_blank"> <h5>Javascript</h5></a>
                            </div>
                            <div className="item">
                            <a href="https://www.typescriptlang.org/" target="_blank"> <img src={typescriptOriginal} alt="Image" /></a>
                            <a href="https://www.typescriptlang.org/" target="_blank"> <h5>TypeScript</h5></a>
                            </div>
                            <div className="item">
                            <a href="https://reactjs.org/" target="_blank"> <img src={reactOriginal} alt="Image" /></a>
                            <a href="https://reactjs.org/" target="_blank"> <h5>Framework JS (ReactJS)</h5></a>
                            </div>
                            <div className="item">
                            <a href="https://www.php.net/" target="_blank"><img src={phpOriginal} alt="Image" /></a>
                            <a href="https://www.php.net/" target="_blank"> <h5>PHP</h5></a>
                            </div>
                            <div className="item">
                            <a href="https://laravel.com/" target="_blank"><img src={laravelOriginal} alt="Image" /></a>
                            <a href="https://laravel.com/" target="_blank"><h5>Framework PHP (Laravel)</h5></a>
                            </div>
                            {/* <div className="item">
                            <a href="https://learn.microsoft.com/fr-fr/dotnet/csharp/" target="_blank"><img src={csharpOriginal} alt="Image" /></a>
                            <a href="https://learn.microsoft.com/fr-fr/dotnet/csharp/" target="_blank"> <h5>C# (Création d'app mobile IOS/Android)</h5></a>
                            </div> */}
                            <div className="item">
                            <a href="https://www.mysql.com/" target="_blank"><img src={mysqlOriginal} alt="Image" /></a>
                            <a href="https://www.mysql.com/" target="_blank"> <h5>MySQL (Gestion de BDD)</h5></a>
                            </div>
                            <div className="item">
                            <a href="https://www.adobe.com/fr/products/photoshop/landpb.html" target="_blank"><img src={photoshop} alt="Image" /></a>
                            <a href="https://www.adobe.com/fr/products/photoshop/landpb.html" target="_blank"> <h5>Logiciel de retouche et de dessins</h5></a>
                            </div>
                            <div className="item">
                            <a href="https://www.adobe.com/fr/products/xd.html" target="_blank"><img src={xd} alt="Image" /></a>
                            <a href="https://www.adobe.com/fr/products/xd.html" target="_blank"> <h5>Logiciel de création de maquette (Web Design)</h5></a>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
  
}
